import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '../utils/auth.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      navShow: false,
      title: '纵信快手项目管理'
    },
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/',

    component: () => import('../views/Home.vue'),
    children: [{
      path: '/',
      name: 'overview',
      meta: {
        navShow: true,
        title: '数据概览',
        url: '/',
        index: 0,
      },
      component: () => import('../views/overview/overview.vue')
    },
    {
      path: '/ksAdvertiser',
      name: 'ksAdvertiser',
      meta: {
        navShow: true,
        title: '快手广告主',
        url: '/ksAdvertiser',
        parentMPName: '数据报表',
        parentPath: '/ksAdvertiser',
        index: 1,
      },
      component: () => import('../views/report/ksadvertiser.vue'),
    }, {
      path: '/dyAdvertiser',
      name: 'dyAdvertiser',
      meta: {
        navShow: true,
        title: '巨量广告主',
        url: '/dyAdvertiser',
        parentMPName: '数据报表',
        parentPath: '/ksAdvertiser',
        index: 2,
      },
      component: () => import('../views/report/dyadvertiser.vue'),
    },
    {
      path: '/tcAdvertiser',
      name: 'tcAdvertiser',
      meta: {
        navShow: true,
        title: '腾讯广告主(旧版)',
        url: '/tcAdvertiser',
        parentMPName: '数据报表',
        parentPath: '/ksAdvertiser',
        index: 3,
      },
      component: () => import('../views/report/tcadvertiser.vue'),
    },
    {
      path: '/tcAdvertiserNew',
      name: 'tcAdvertiserNew',
      meta: {
        navShow: true,
        title: '腾讯广告主(新版)',
        url: '/tcAdvertiserNew',
        parentMPName: '数据报表',
        parentPath: '/ksAdvertiser',
        index: 4,
      },
      component: () => import('../views/report/tcadvertiserNew.vue'),
    },
    {
      path: '/clientKsAdvertiser',
      name: 'clientKsAdvertiser',
      meta: {
        navShow: true,
        title: '客户报表-快手',
        url: '/clientKsAdvertiser',
        parentMPName: '数据报表',
        parentPath: '/ksAdvertiser',
        index: 5
      },
      component: () => import('../views/report/clientKsAdvertiser.vue'),
    },
    {
      path: '/clientOcAdvertiser',
      name: 'clientOcAdvertiser',
      meta: {
        navShow: true,
        title: '客户报表-巨量',
        url: '/clientOcAdvertiser',
        parentMPName: '数据报表',
        parentPath: '/ksAdvertiser',
        index: 6
      },
      component: () => import('../views/report/clientOcAdvertiser.vue'),
    },
    {
      path: '/poductReport',
      name: 'poductReport',
      meta: {
        navShow: true,
        title: '商品报表',
        url: '/poductReport',
        parentMPName: '数据报表',
        parentPath: '/ksAdvertiser',
        index: 7
      },
      component: () => import('../views/report/poductReport.vue'),
    },
    {
      path: '/transfer',
      name: 'transfer',
      meta: {
        navShow: true,
        title: '拼多多转链',
        url: '/transfer',
        parentMPName: '商品转链',
        parentPath: '/transfer',
      },
      component: () => import('../views/transfer/merchandise.vue'),
    }, {
      path: '/record',
      name: 'record',
      meta: {
        navShow: true,
        title: '转链记录',
        url: '/record',
        parentMPName: '商品转链',
        parentPath: '/transfer',
      },
      component: () => import('../views/transfer/record.vue'),
    },
    {
      path: '/platform',
      name: 'platform',
      meta: {
        navShow: true,
        title: '广告投放',
        url: '/platform',
      },
      component: () => import('../views/platform/launch.vue'),
    },
    {
      path: '/cashgift',
      name: 'cashgift',
      meta: {
        navShow: true,
        title: '多多礼金',
        url: '/cashgift',
      },
      component: () => import('../views/cashgift/index.vue'),
    },
    {
      path: '/taotransfer',
      name: 'taotransfer',
      meta: {
        navShow: true,
        title: '淘宝转链',
        url: '/taotransfer',
        parentMPName: '商品转链',
        parentPath: '/transfer',
      },
      component: () => import('../views/transfer/taotransfer.vue'),
    }, {
      path: '/jingtransfer',
      name: 'jingtransfer',
      meta: {
        navShow: true,
        title: '京东转链',
        url: '/jingtransfer',
        parentMPName: '商品转链',
        parentPath: '/transfer',
      },
      component: () => import('../views/transfer/jingtransfer.vue'),
    }, {
      path: '/team',
      name: 'team',
      meta: {
        navShow: true,
        title: '团队',
        parentMPName: '系统管理',
        parentPath: '/team',
      },
      component: () => import('@/views/systemmanagement/team.vue')
    }, {
      path: '/tbProcedureAuth',
      name: 'tbProcedureAuth',
      meta: {
        navShow: true,
        title: '淘宝小程序授权',
        parentMPName: '系统监控',
        parentPath: '/tbProcedureAuth',
      },
      component: () => import('@/views/systemmonitoring/tbProcedureAuth.vue')
    }, {
      path: '/dataCheck',
      name: 'dataCheck',
      meta: {
        navShow: true,
        title: '数据拉取校验',
        parentMPName: '系统监控',
        parentPath: '/tbProcedureAuth',
      },
      component: () => import('@/views/systemmonitoring/dataCheck.vue')
    }, {
      path: '/abnormalAccount',
      name: 'abnormalAccount',
      meta: {
        navShow: true,
        title: '异常账户列表',
        parentMPName: '系统监控',
        parentPath: '/tbProcedureAuth',
      },
      component: () => import('@/views/systemmonitoring/abnormalAccount.vue')
    }, {
      path: '/pddGoodsCheck',
      name: 'pddGoodsCheck',
      meta: {
        navShow: true,
        title: '拼多多异常商品',
        parentMPName: '系统监控',
        parentPath: '/tbProcedureAuth',
      },
      component: () => import('@/views/systemmonitoring/pddGoodsCheck.vue')
    }, {
      path: '/kuaishou',
      name: 'kuaishou',
      meta: {
        navShow: true,
        title: '快手账户',
        parentMPName: '账户管理',
        parentPath: '/kuaishou',
      },
      component: () => import('@/views/accountmanagement/kuaishou.vue')
    }, {
      path: '/douyin',
      name: 'douyin',
      meta: {
        navShow: true,
        title: '巨量账户',
        parentMPName: '账户管理',
        parentPath: '/kuaishou',
      },
      component: () => import('@/views/accountmanagement/douyin.vue')
    },
    {
      path: '/dyAuthorize',
      name: 'dyAuthorize',
      meta: {
        navShow: true,
        title: '巨量授权',
        parentMPName: '账户管理',
        parentPath: '/kuaishou',
      },
      component: () => import('@/views/accountmanagement/dyAuthorize.vue')
    },
    {
      path: '/tencent',
      name: 'tencent',
      meta: {
        navShow: true,
        title: '腾讯账户',
        parentMPName: '账户管理',
        parentPath: '/kuaishou',
      },
      component: () => import('@/views/accountmanagement/tencent.vue')
    }, {
      path: '/tcAdgroup',
      name: 'tcAdgroup',
      meta: {
        navShow: true,
        title: '广告账户',
        parentMPName: '账户管理',
        parentPath: '/kuaishou',
      },
      component: () => import('@/views/accountmanagement/tcAdgroup.vue')
    }, {
      path: '/clientOpen',
      name: 'clientOpen',
      meta: {
        navShow: true,
        title: '客户账号',
        parentMPName: '账户管理',
        parentPath: '/kuaishou'
      },
      component: () => import('@/views/accountmanagement/clientOpen.vue')
    }, {
      path: '/clientAdvertise',
      name: 'clientAdvertise',
      meta: {
        navShow: true,
        title: '广告主',
        parentMPName: '账户管理',
        parentPath: '/kuaishou'
      },
      component: () => import('@/views/accountmanagement/clientAdvertise.vue')
    }, {
      path: '/setPageLoading',
      name: 'setPageLoading',
      meta: {
        navShow: true,
        title: '新建落地页',
        parentMPName: '落地页管理',
        parentPath: '/setPageLoading',
      },
      component: () => import('@/views/pageLoading/setPageLoading.vue')
    }, {
      path: '/pageLoadingList',
      name: 'pageLoadingList',
      meta: {
        navShow: true,
        title: '落地页列表',
        parentMPName: '落地页管理',
        parentPath: '/setPageLoading',
      },
      component: () => import('@/views/pageLoading/pageLoadingList.vue')
    }, {
      path: '/tokenmanage',
      name: 'tokenmanage',
      meta: {
        navShow: true,
        title: 'Token管理',
        parentMPName: '系统管理',
        parentPath: '/team',
      },
      component: () => import('@/views/systemmanagement/tokenmanage.vue')
    }, {
      path: '/MarketingApi',
      name: 'MarketingApi',
      meta: {
        navShow: true,
        title: 'MarketingApi',
        parentMPName: '系统管理',
        parentPath: '/team',
      },
      component: () => import('@/views/systemmanagement/MarketingApi.vue')
    }, {
      path: '/order',
      name: 'order',
      meta: {
        navShow: true,
        title: '全平台订单',
        parentMPName: '订单管理',
        parentPath: '/order',
      },
      component: () => import('../views/order/order.vue')
    }, {
      path: '/orderpdd',
      name: 'orderpdd',
      meta: {
        navShow: true,
        title: '拼多多订单',
        parentMPName: '订单管理',
        parentPath: '/order',
      },
      component: () => import('../views/order/pddorder.vue')
    }, {
      path: '/Dordertb',
      name: 'Dordertb',
      meta: {
        navShow: true,
        title: '淘宝订单 (点点诺)',
        parentMPName: '订单管理',
        parentPath: '/order',
      },
      component: () => import('../views/order/DdnTaoOrder.vue')
    }, {
      path: '/DMordertb',
      name: 'DMordertb',
      meta: {
        navShow: true,
        title: '淘宝订单 (大麦商管)',
        parentMPName: '订单管理',
        parentPath: '/order',
      },
      component: () => import('../views/order/DaMaiTao.vue')
    }, {
      path: '/Hordertb',
      name: 'Hordertb',
      meta: {
        navShow: true,
        title: '淘宝订单 (杭州)',
        parentMPName: '订单管理',
        parentPath: '/order',
      },
      component: () => import('../views/order/HzTaoOrder.vue')
    }, {
      path: '/orderjd',
      name: 'orderjd',
      meta: {
        navShow: true,
        title: '京东订单',
        parentMPName: '订单管理',
        parentPath: '/order',
      },
      component: () => import('../views/order/jdorder.vue')
    }, {
      path: '/roleaction',
      name: 'roleaction',
      meta: {
        navShow: true,
        title: '权限角色',
        parentMPName: '权限管理',
        parentPath: '/roleaction',
      },
      component: () => import('@/views/systemmanagement/systemprivileges/roleaction.vue')
    }, {
      path: '/routeraddress',
      name: 'routeraddress',
      meta: {
        navShow: true,
        title: '路由地址',
        parentMPName: '权限管理',
        parentPath: '/roleaction',
      },
      component: () => import('@/views/systemmanagement/systemprivileges/routeraddress.vue')
    }, {
      path: '/groupname',
      name: 'groupname',
      meta: {
        navShow: true,
        title: '栏目组名称',
        parentMPName: '权限管理',
        parentPath: '/roleaction',
      },
      component: () => import('@/views/systemmanagement/systemprivileges/groupname.vue')
    }, {
      path: '/ksdetails',
      name: 'ksdetails',
      meta: {
        navShow: true,
        title: '快手广告计划',
      },
      component: () => import('../views/report/ksdetails.vue')
    }, {
      path: '/dydetails',
      name: 'dydetails',
      meta: {
        navShow: true,
        title: '巨量广告计划'
      },
      component: () => import('../views/report/dydetails.vue')
    }, {
      path: '/clientKsDetails',
      name: 'clientKsDetails',
      meta: {
        navShow: true,
        title: '快手客户广告计划'
      },
      component: () => import('../views/report/clientKsDetails.vue')
    }, {
      path: '/clientOcDetails',
      name: 'clientOcDetails',
      meta: {
        navShow: true,
        title: '巨量客户广告计划'
      },
      component: () => import('../views/report/clientOcDetails.vue')
    }, {
      path: '/tcDetails',
      name: 'tcDetails',
      meta: {
        navShow: true,
        title: '腾讯广告计划(旧版)'
      },
      component: () => import('../views/report/tcdetails.vue')
    },
    {
      path: '/tcDetailsNew',
      name: 'tcDetailsNew',
      meta: {
        navShow: true,
        title: '腾讯广告计划(新版)'
      },
      component: () => import('../views/report/tcdetailsNew.vue')
    },
    {
      path: '/ksConfiguration',
      name: 'ksConfiguration',
      meta: {
        navShow: true,
        title: '快手账户配置',
        url: '/ksConfiguration',
        parentMPName: '账户配置',
        parentPath: '/ksConfiguration',
      },
      component: () => import('../views/accountConfiguration/ksConfiguration.vue'),
    }, {
      path: '/jlConfiguration',
      name: 'jlConfiguration',
      meta: {
        navShow: true,
        title: '巨量账户配置',
        url: '/jlConfiguration',
        parentMPName: '账户配置',
        parentPath: '/ksConfiguration',
      },
      component: () => import('../views/accountConfiguration/jlConfiguration.vue'),
    }, {
      path: '/tcConfiguration',
      name: 'tcConfiguration',
      meta: {
        navShow: true,
        title: '腾讯账户配置',
        url: '/tcConfiguration',
        parentMPName: '账户配置',
        parentPath: '/ksConfiguration',
      },
      component: () => import('../views/accountConfiguration/tcConfiguration.vue'),
    }, {
      path: '/clientConfiguration',
      name: 'clientConfiguration',
      meta: {
        navShow: true,
        title: '客户账户配置',
        url: '/clientConfiguration',
        parentMPName: '账户配置',
        parentPath: '/ksConfiguration',
      },
      component: () => import('../views/accountConfiguration/clientConfiguration.vue'),
    }, {
      path: '/pddOrderRebate',
      name: 'pddOrderRebate',
      meta: {
        navShow: true,
        title: '拼多多订单返佣明细',
        url: '/pddOrderRebate',
        parentMPName: '财务报表',
        parentPath: '/pddOrderRebate',
      },
      component: () => import('../views/financialStatement/pddOrderRebate.vue'),
    }, {
      path: '/adCostOrder',
      name: 'adCostOrder',
      meta: {
        navShow: true,
        title: '广告主消耗及订单',
        url: '/adCostOrder',
        parentMPName: '财务报表',
        parentPath: '/pddOrderRebate',
      },
      component: () => import('../views/financialStatement/adCostOrder.vue'),
    },

    {
      path: '/cashGiftAccount',
      name: 'cashGiftAccount',
      meta: {
        navShow: true,
        title: '多多礼金账户',
        url: '/cashGiftAccount',
        parentMPName: '财务报表',
        parentPath: '/pddOrderRebate',
      },
      component: () => import('../views/financialStatement/cashGiftAccount.vue'),
    },

    {
      path: '/pddReport',
      name: 'pddReport',
      meta: {
        navShow: true,
        title: '拼多多商品报备',
        url: '/pddReport',
        parentMPName: '报备中心',
        parentPath: '/pddReport',
      },
      component: () => import('../views/goodsReport/pddReport.vue'),
    },

    {
      path: '/jlRefund',
      name: 'jlRefund',
      meta: {
        navShow: true,
        title: '退款分析列表',
        url: '/jlRefund',
        parentMPName: '退款分析',
        parentPath: '/refund',
      },
      component: () => import('../views/refund/jlRefund.vue'),
    },

    // 辅助工具
    {
      path: '/ksSDPA',
      name: 'ksSDPA',
      meta: {
        navShow: true,
        title: '快手SDPA回传',
        url: '/ksSDPA',
        parentMPName: '辅助工具',
        parentPath: '/tool',
      },
      component: () => import('@/views/tool/ksSDPA.vue'),
    },
    {
      path: '/jlComment',
      name: 'jlComment',
      meta: {
        navShow: true,
        title: '巨量评论列表',
        url: '/jlComment',
        parentMPName: '辅助工具',
        parentPath: '/tool',
      },
      component: () => import('@/views/tool/jlComment.vue'),
    },

    //系统配置
    {
      path: '/systemConfiguration',
      name: 'systemConfiguration',
      meta: {
        navShow: true,
        title: '系统配置',
        url: '/systemConfiguration',
        parentMPName: '系统管理',
        parentPath: '/team',
      },
      component: () => import('../views/systemmanagement/configuration.vue'),
    },
    {
      path: '/niuConfiguration',
      name: 'niuConfiguration',
      meta: {
        navShow: true,
        title: '磁力金牛配置',
        url: '/niuConfiguration',
        parentMPName: '账户配置',
      },
      component: () => import('../views/accountConfiguration/niuConfiguration.vue'),
    },

    ]
  },
]

// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const Token = getToken('vue_admin_template_token')
  const userInfo = JSON.parse(sessionStorage.getItem("userINFO"))
  if (Token && userInfo != null) {
    //登陆
    next()
  } else {
    //没有登陆
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  }

})
export default router
